<template>
  <div>
    <div
      class="p4a__tax-invoice-pdf"
    >
      <div class="p4a__tax-invoice-pdf-top">
        <div
          class="p4a__receipt-content"
        >
          <table style="width: 100%; border-collapse: separate; border-spacing: 10px 5px;">
            <thead style="display: table-header-group;">
              <tr>
                <td>
                  <!-- Header -->
                  <div
                    id="main-header"
                    class="invoice-padding pb-0"
                  >
                    <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0" />
                    <!-- Spacer -->
                    <div
                      class="d-flex justify-content-between invoice-spacing mt-0"
                      style="font-family: Inter;"
                    >
                      <div style="display: flex; align-items: end;">
                        <div
                          v-if="invoice.nvsFileType == 'Funeral Service Package'"
                          style="min-width: fit-content; width: fit-content;"
                        >
                          <h4 style="font-family: Eras Demi ITC; font-weight: 400; font-size: 29px; line-height: 30px; text-align: left; color: rgba(16, 77, 157, 1);">
                            Nirvana Memorial Garden Pte Ltd <span style="font-size: 11px; color: rgba(51, 51, 51, 1);">(200720733M)</span>
                          </h4>
                          <p style="font-family: Inter; text-align: left; color: rgba(51, 51, 51, 1); font-weight: 400; font-size: 14px; line-height: 18.2px; margin-bottom: 0;">
                            GST Registration Number: M90364395L
                          </p>
                        </div>
                        <div
                          v-else
                          style="min-width: fit-content; width: fit-content;"
                        >
                          <h4 style="font-family: Eras Demi ITC; font-weight: 400; font-size: 29px; line-height: 30px; text-align: left; color: rgba(16, 77, 157, 1);">
                            Mount Prajna Ltd <span style="font-size: 11px; color: rgba(51, 51, 51, 1);">(200401183W)</span>
                          </h4>
                          <p style="font-family: Inter; text-align: left; color: rgba(51, 51, 51, 1); font-weight: 400; font-size: 14px; line-height: 18.2px; margin-bottom: 0;">
                            GST Registration Number: M90364395L
                          </p>
                        </div>
                      </div>
                      <div style="margin-top: 5px;">
                        <img :src="logoImg">
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <!-- Invoice Client & Payment Details -->
                  <div
                    class="invoice-padding main__body po__create preview_order_pdf"
                  >
                    <div
                      style="display: flex; justify-content: space-between; margin-top: 75px; margin-bottom: 53px;"
                    >
                      <div>
                        <h4 class="purchase-info__title">
                          {{ purchaser.name }} ({{ purchaser.nric ? (purchaser.nric[0] + purchaser.nric.slice(1).replace(/.(?=.{4,}$)/g, 'X')) : '-' }})
                        </h4>
                        <div class="purchase-info__single">
                          <span class="purchase-info__single-value">{{ purchaser.customer ? purchaser.customer.perAddress1 : '-' }}</span>
                        </div>
                        <div class="purchase-info__single">
                          <span class="purchase-info__single-value">{{ purchaser.customer ? purchaser.customer.perAddress2 : '-' }}</span>
                        </div>
                        <div class="purchase-info__single">
                          <span class="purchase-info__single-value">{{ purchaser.customer ? purchaser.customer.perCountry : '-' }} {{ purchaser.customer ? purchaser.customer.perPostcode : '-' }}</span>
                        </div>
                      </div>
                      <div
                        class="purchase-info"
                      >
                        <div>
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              File No.
                            </strong>
                            <span class="purchase-info__single-value">{{ invoice.fileNo }}</span>
                          </div>
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              PO No.
                            </strong>
                            <span class="purchase-info__single-value">{{ (invoice.nvsFileID && invoice.nvsFileID.poNo) ? invoice.nvsFileID.poNo : '-' }}</span>
                          </div>
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              Lot No.
                            </strong>
                            <span class="purchase-info__single-value">{{ invoice.lotLocation || '-' }}</span>
                          </div>
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              Lot Type
                            </strong>
                            <span class="purchase-info__single-value">{{ (invoice.nvsFileID && invoice.nvsFileID.lotType) ? invoice.nvsFileID.lotType : '-' }}</span>
                          </div>
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              Date
                            </strong>
                            <span class="purchase-info__single-value">{{ invoice.trxDate ? dateFormat(invoice.trxDate) : '-' }}</span>
                          </div>
                        </div>
                      </div>
                      <div style="margin-top: 5px;">
                        <h4
                          v-if="invoiceAgent"
                          style="font-family: Inter; text-align: left; color: #DC1B1B; font-weight: 600; font-size: 24px; line-height: 32px;"
                        >
                          Agent’s Copy
                        </h4>
                        <h4 style="font-family: Inter; text-align: left; color: rgba(51, 51, 51, 1); font-weight: 700; font-size: 40px; line-height: 32px;">
                          Tax Invoice
                        </h4>
                        <h6 style="font-family: Inter; text-align: left; color: rgba(51, 51, 51, 1); font-weight: 600; font-size: 24px; line-height: 32px; margin-bottom: 0;">
                          {{ invoice.trxNo }}
                        </h6>
                      </div>
                    </div>
                    <b-row style="margin-top: 36px">
                      <b-col
                        cols="12"
                      >
                        <b-table-simple
                          class="position-relative has_padding receipt-pdf__table"
                          responsive
                          caption-top
                        >
                          <b-thead>
                            <b-tr class="group__tag">
                              <th style="padding-left: 0;">
                                Description
                              </th>
                              <th style="text-align: center!important;">
                                Quantity
                              </th>
                              <th style="text-align: right;">
                                Amount (SGD)
                              </th>
                            </b-tr>
                          </b-thead>
                          <b-tbody
                            class="white-body-background"
                          >
                            <tr
                              v-for="(item, key) in invoice.billings"
                              :key="key"
                              style="page-break-inside: avoid; page-break-after: auto;"
                            >
                              <b-td
                                style="page-break-inside: avoid; page-break-after: auto;"
                              >
                                {{ item.item }}
                              </b-td>
                              <b-td style="text-align: center!important;">
                                {{ item.quantity }}
                              </b-td>
                              <b-td style="text-align: right;">
                                <strong class="p4a__pdf-text-bold">
                                  {{ item.amount >= 0 ? numberFormat(item.amount) : `(${numberFormat(Math.abs(item.amount))})` }}
                                </strong>
                              </b-td>
                            </tr>
                            <tr>
                              <b-td />
                              <b-td />
                              <b-td style="display: flex; justify-content: end;">
                                <div style="display: flex;">
                                  <span class="p4a__pdf-sub-total">Sub Total</span>
                                  <div>
                                    <div style="width: 130px; height: 1px; background-color: #333333; margin-top: -10px;" />
                                    <div
                                      class="p4a__pdf-sub-total-value p4a__pdf-text-bold"
                                      style="text-align: right; margin-top: 10px;"
                                    >
                                      {{ numberFormat(invoice.amount) }}
                                    </div>
                                  </div>
                                </div>
                              </b-td>
                            </tr>
                            <!-- <tr>
                              <b-td />
                              <b-td />
                              <b-td style="display: flex; justify-content: end;">
                                <div style="display: flex;">
                                  <span class="p4a__pdf-sub-total">8% GST</span>
                                  <div>
                                    <div style="width: 130px; height: 1px; background-color: #333333; margin-top: -10px;" />
                                    <div
                                      class="p4a__pdf-sub-total-value p4a__pdf-text-bold"
                                      style="text-align: right; margin-top: 10px;"
                                    >
                                      1,664.00
                                    </div>
                                  </div>
                                </div>
                              </b-td>
                            </tr> -->
                            <tr>
                              <b-td />
                              <b-td />
                              <b-td style="display: flex; justify-content: end;">
                                <div style="display: flex;">
                                  <strong
                                    class="p4a__pdf-sub-total p4a__pdf-text-bold"
                                    style="text-align: left;"
                                  >
                                    Total
                                  </strong>
                                  <div>
                                    <div style="width: 130px; height: 1px; background-color: #333333; margin-top: -10px;" />
                                    <div
                                      class="p4a__pdf-text-bold"
                                      style="text-align: right; margin-top: 10px; margin-bottom: 10px;"
                                    >
                                      {{ numberFormat(invoice.amount) }}
                                    </div>
                                    <div style="width: 130px; height: 1px; background-color: #333333; margin-bottom: -6px;" />
                                    <div style="width: 130px; height: 1px; background-color: #333333; margin-bottom: -5px;" />
                                  </div>
                                </div>
                              </b-td>
                            </tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div style="padding-top: 62px; padding-left: 14px;">
        <p style="font-family: Inter; font-size:14px; font-weight: 400; line-height: 16.94px; text-transform: uppercase; text-align: left; color: rgba(51, 51, 51, 1);">
          this is a computer-generated INVOICE. No signature is required.
        </p>
      </div>
      <div style="position: absolute; bottom: 2%; left: 0; width: 100%; text-align: center;">
        <p style="font-family: Eras Medium ITC; font-size:14px; font-weight: 400; line-height: 16.94px; text-transform: capitalize; color: rgba(51, 51, 51, 1);">
          950 Old Chua Chu Kang Road Singapore 699816 Tel: (65) 6397 2272    Fax: (65) 6795 3070
        </p>
        <p style="font-family: Eras Bold ITC; font-size: 11px; font-weight: 400; line-height: 16.94px; text-transform: capitalize; color: rgba(51, 51, 51, 1);">
          <strong><span class="p4a__country-star">*</span>MALAYSIA</strong> Kuala Lumpur Semenyih Shah Alam Klang Kulai Ulu Tiram Segamat Bukit Mertajam Penang Island Sungai Petani Kota Konabalu Sibu Malacca  <strong><span class="p4a__country-star">*</span>SINGAPORE</strong> <strong><span class="p4a__country-star">*</span>THAILAND</strong> <strong><span class="p4a__country-star">*</span>INDONESIA</strong> <strong><span class="p4a__country-star">*</span>VIETNAM</strong>
        </p>
        <div class="p4a__bottom-social-info">
          <div style="display: flex; align-items: center; margin-right: 10px;">
            <h5 style="font-family: Eras Demi ITC; font-weight: 400; font-size: 15px; text-align: left; color: rgba(16, 77, 157, 1); margin-bottom: 0">
              www.nirvana.com.sg
            </h5>
          </div>
          <div class="p4a__social-icon_group">
            <a
              href="#!"
              class="p4a__social-icon"
            ><img
              :src="facebookIcon"
              class="p4a__social-icon_img"
              alt="facebook"
            ></a>
            <a
              href="#!"
              class="p4a__social-icon"
            ><img
              :src="youtubeIcon"
              class="p4a__social-icon_img"
              alt="youtube"
            ></a>
            <a
              href="#!"
              class="p4a__social-icon"
            ><img
              :src="instagramIcon"
              class="p4a__social-icon_img"
              alt="instagram"
            ></a>
          </div>
          <div style="display: flex; align-items: center;">
            <h5 style="font-family: Eras Demi ITC; font-weight: 400; font-size: 15px; text-align: left; color: rgba(51, 51, 51, 1); margin-bottom: 0">
              nmg.singapore
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div></template>
<script>
import {
  BRow, BCol, BTableSimple, BTbody, BThead, BTr, BTd,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTd,

  },
  data() {
    return {
      generateNow: false,
      elHeight: 0,
      gapHeight: 1,
      numberOfEl: 45,
      invoice: JSON.parse(localStorage.getItem('invoiceDetail')),
      receipts: JSON.parse(localStorage.getItem('receiptsList')),
      invoiceAgent: JSON.parse(localStorage.getItem('invoiceAgent')),
      // invoice: {},
      // receipts: [],
      /* eslint-disable global-require */
      logoImg: require('@/assets/images/logo/tax-invoice-logo.png'),
      facebookIcon: require('@/assets/images/icons/social/facebook.png'),
      youtubeIcon: require('@/assets/images/icons/social/youtube.png'),
      instagramIcon: require('@/assets/images/icons/social/instagram.png'),
      purchaser: {},
    }
  },
  // beforeMount() {
  //   this.$http.get('customer/invoices/1206234/show')
  //     .then(response => {
  //       this.invoice = response.data.invoice
  //       this.receipts = response.data.receipts
  //       localStorage.setItem('invoiceDetail', JSON.stringify(this.invoice))
  //       localStorage.setItem('receiptsList', JSON.stringify(this.receipts))
  //       this.purchaser = (this.invoice.purchasers && this.invoice.purchasers.length) ? this.invoice.purchasers[0] : {}
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  // },
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.purchaser = (this.invoice.purchasers && this.invoice.purchasers.length) ? this.invoice.purchasers[0] : {}
  },
}
</script>
<style lang="scss">
    body div#app.semi-dark-layout {
        background: #fff !important;
        background-repeat: no-repeat !important;
        background-position: center bottom !important;
        background-size: 100% auto !important;
    }
    body {
        background: #fff !important;
    }
    .card {
        box-shadow: none !important;
    }
    .receipt-pdf__table table{
        border: none;
    }
    .receipt-pdf__table table tr th{
        border-top: none;
        background: #7d7d7d00 !important;
        border-bottom: 2px solid rgba(102, 102, 102, 0.1);
        font-family: Inter;
        font-size: 18px !important;
        font-weight: 600;
        text-align: left;
        color: rgba(51, 51, 51, 1) !important;
        text-transform: capitalize;
        padding: 10px 21px !important;
        &:first-child{
            padding-left: 0 !important;
        }
        &:last-child{
          padding-right: 0 !important;
        }
    }
    .receipt-pdf__table table tr td{
        border-top: none;
        font-family: Inter;
        font-size: 18px !important;
        font-weight: 400;
        text-align: left;
        color: rgba(51, 51, 51, 1) !important;
        background: #7d7d7d00 !important;
        padding: 10px 21px !important;
        &:first-child{
            padding-left: 0 !important;
        }
        &:last-child{
          padding-right: 0 !important;
        }
    }
    .td__bg-white {
      background: #fff !important;
    }
    .td__border{
        border-top: 2px solid rgba(0, 0, 0, 1) !important;
        border-bottom: 2px solid rgba(0, 0, 0, 1) !important;
    }
    .purchase-info{
        min-width: 290px;
    }
    .purchase-info__title{
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
    }
    .purchase-info__single{
        display: flex;
        justify-content: space-between;
    }
    .purchase-info__single-title{
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      line-height: 21.78px;
      text-align: left;
    }
    .purchase-info__single-value{
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      text-align: right;
    }
    .p4a__pdf-sub-total{
      min-width: 160px;
      text-align: left;
    }
    .p4a__pdf-sub-total-value{
      min-width: 130px;
      text-align: right;
    }
    .p4a__pdf-text-bold{
      font-size: 18px;
      font-weight: 700;
      text-align: right;
      color: rgba(51, 51, 51, 1);
    }
    .p4a__pdf-single-border{
      font-size: 18px;
      font-weight: 700;
      text-align: right;
      color: rgba(51, 51, 51, 1);
      min-width: 130px;
      display: inline-block;
      border-bottom: 1px solid rgba(51, 51, 51, 1);
    }
    .p4a__pdf-double-border{
      font-size: 18px;
      font-weight: 700;
      text-align: right;
      color: rgba(51, 51, 51, 1);
      min-width: 130px;
      display: inline-block;
      border-top: 1px solid rgba(51, 51, 51, 1);
      border-bottom: 4px double rgba(51, 51, 51, 1);
    }
</style>
<style>
    @media print {
      html, body {
        height: 99%;
      }

      .p4a__tax-invoice-pdf{
          padding: 32px 60px 0 60px !important;
      }
    }
</style>
